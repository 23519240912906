<template>
  <button
    type="button"
    class="rounded-md focus:outline-none"
    :aria-label="t('Закрити')"
  >
    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
  </button>
</template>

<script setup>
import { XMarkIcon } from '@heroicons/vue/24/solid'
const { t } = useI18n()
</script>
